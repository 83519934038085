<template>
  <v-card class="ml-0 ml-md-6 py-6 px-4 px-md-16 rounded-lg full-width" color="light_gray" tile elevation="0">
    <v-row align="center" no-gutters>
      <v-col cols="12" md="12" lg="10">
        <v-form ref="deleteAccountForm" @submit.prevent="submitDeleteAccount">
          <v-row>
            <v-col cols="6">
              <span class="gray--text font-weight-bold text-body-1 text-capitalize">{{ $t(`label.deleteAccountHeadline`) }}</span>
            </v-col>

            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
            <template>
              <v-row class="pl-md-2 px-2">
                <v-col cols="12">
                  <div class="text-body-2 unbox_secondary--text" v-html="$t(`label.deleteAccountMessage`)"></div>
                </v-col>
                <v-col cols="12" class="py-1">
                  <app-form-field
                    v-model.trim="deleteAccountForm.password"
                    type="password"
                    :label="$t(`label.password`)"
                    :placeholder="$t(`label.retypePassword`)"
                    :rules="$validation.passwordRules()"
                  ></app-form-field>
                </v-col>

                <v-col cols="12">
                  <div class="text-center">
                    <v-btn
                      class="full-width white--text full-height text-body-1 rounded-lg font-weight-bold text-capitalize px-8"
                      type="submit"
                      height="56px"
                      max-width="300px"
                      color="red"
                      elevation="0"
                    >
                      {{ $t(`label.deleteAccount`) }}
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </template>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { CUSTOMER_DELETE_ACCOUNT } from '@/store/customer.module'
import { SESSION } from '@/constants'
export default {
  name: 'deleteAccount',
  data: () => ({
    deleteAccountForm: {
      password: '',
      uuid: ''
    }
  }),
  computed: {
    customerInfo() {
      return this.$store.state.customer.info
    }
  },
  created() {},
  methods: {
    submitDeleteAccount() {
      if (this.$refs.deleteAccountForm.validate()) {
        let data = this.deleteAccountForm
        this.$store.dispatch(CUSTOMER_DELETE_ACCOUNT, { data })
      }
    }
  },
  mounted() {
    this.deleteAccountForm.uuid = localStorage.getItem(SESSION.CUSTOMER_UUID)
  }
}
</script>
<style lang="scss" scoped>
.profile-address-table td {
  vertical-align: top;
}
</style>
